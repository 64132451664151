.social-icons {
    display: flex;
    gap: 10px;
}

.social-icon img {
    width: 30px;
    /* Adjust size as necessary */
    height: 30px;
}
.getIn{
    color: #060607;
        font-family: "Poppins", Sans-serif;
        font-size: 31px;
        font-weight: 700;
}
.form-p{
    color: #4B4F58;
        font-family: "Roboto", Sans-serif;
        font-size: 16px;
        font-weight: 400;
}
.form-label{
    font-size: 16px;
        color: #4b4f58;
        display: block;
        /* margin-bottom: 5px; */
        font-weight: bold;
}
.form-input{
    width: 100%;
        /* padding: 15px; */
        border: 1px solid #e2e2e2;
        border-radius: 5px;
        margin-bottom: 20px;
        font-size: 16px;
        background-color: #f9f9f9;
        outline: none;
        box-shadow: none;
        transition: all 0.3s ease;
        width: 100% !important;
}
.form-btn{
background-color: #ff6d00;
    color: #fff;
    padding: 15px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
.emailus{
    color: #2B2C31;
        font-family: "Poppins", Sans-serif;
        font-size: 28px;
        font-weight: 600;
        text-transform: none;
        font-style: normal;
        text-decoration: none;
        line-height: 35px;
}
.support-vyatra{
    color: #e86b26;
        font-family: "Poppins", Sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 23px;
}

.elementor-widget .elementor-icon-list-items.elementor-inline-items {
    margin-right: -8px;
    margin-left: -8px;
}
.elementor-widget .elementor-icon-list-items.elementor-inline-items .elementor-icon-list-item {
    margin-right: 8px;
    margin-left: 8px;
    list-style-type: none;
}
ul.elementor-icon-list-items.elementor-inline-items {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
}
.elementor-icon-list-items .elementor-inline-items{
    list-style-type: none;
}
.elementor-widget .elementor-icon-list-items {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.elementor-icon-list-item {
    background: #e86b26;
    padding: 10px !important;
    border-radius: 50%;
    height: 35px;
}
.custom-select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='orange' d='M7 10l5 5 5-5z'/%3E%3C/svg%3E") no-repeat right 10px center;
    background-color: white;
    background-size: 2em;
    padding-right: 2.5em;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  