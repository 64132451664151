.custom-card {
    border-radius: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

.icon-number {
    background-color: #ff5722;  /* Orange background for the number */
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.number {
    font-size: 28px;
    color: white;
}
.heading-title {
    color: #2B2C31;
    font-family: "Poppins", Sans-serif;
    font-size: 28px;
    font-weight: 600;
    text-transform: none;
    font-style: normal;
    text-decoration: none;
    line-height: 35px;
}
.heading-text{
    text-align: left;
    color: #7A7E94;
    font-family: "Poppins", Sans-serif;
    font-size: 16px;
    font-weight: 400;
    text-transform: none;
    font-style: normal;
    text-decoration: none;
    line-height: 20px;
}

.icon {
    width: 50px;
    height: 50px;
}

.people-image {
    max-width: 100%;
    height: auto;
    object-fit: cover;
}

ul {
    padding-left: 20px;
}

ul li {
    list-style: disc;
}

.guides {
    display: flex;
    flex-direction: column;
}

.guide-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}
.getTouch-btn{
    background-color: #ff5722 !important;
    color: white;

}
.getTouch-btn:hover{
    background-color: aqua;
}
.map-bg{
    background-image: url('./images/Map-1.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 30px;
    border-radius: 20px;
    margin-bottom: 60px;
}
.map-h1{
    color: #2B2C31;
    font-family: "Poppins", Sans-serif;
    font-size: 24px;
    font-weight: 600;
    font-style: normal;
    text-decoration: none;
    line-height: 30px;
    margin-bottom: 28px;
}
.map-p{
    color: #7A7E94;
    font-family: "Roboto", Sans-serif;
    font-size: 16px;
    font-weight: 400;
    text-transform: none;
    line-height: 20px;
    margin-bottom: 28px;
}
.map-card{
    border-radius: 20px;
    padding:80px;
}
.map-btn{
    background-color: black;
    font-family: "Poppins", Sans-serif;
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
    color: white;
    width: 70%;
    padding: 10px 20px;
    border-radius: 30px;
    border: 1px solid #815BEE;
    margin-bottom: 30px;
}
.map-btn:hover{
    background-color: #815BEE;
}
.guide-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 30px;
    
}

.guide-title {
    font-family: "Poppins", Sans-serif;
    font-size: 24px;
    font-weight: 600;
    color: #2B2C31;
    margin-bottom: 20px;
}

.guide-container {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Spacing between guide cards */
}

.guide-card1 {
    display: flex;
    align-items: center;
    background-color: white;
    padding: 15px;
    border-radius: 50px;
    width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: -10px;
}
.guide-card2{
    display: flex;
    align-items: center;
    background-color: white;
    padding: 15px;
    border-radius: 50px;
    width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 80px; /* Move up slightly */
    margin-left: 250px; /* Shift to the right */
}
@media (max-width: 576px) {
    .map-card{
        padding:30px;
    }
    .guide-card1{
        margin-top: 30px;
        margin-left: -30px;
    }
   .guide-card2{
    margin-top: 30px;
    margin-left: -30px;
   }
   .guide-card3{
    margin-top: 30px;
    margin-left: -30px;
   }
   .map-btn{
       width: 100%;
   }
}

.guide-card3 {
    display: flex;
    align-items: center;
    background-color: white;
    padding: 15px;
    border-radius: 50px;
    width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.guide-image {
    width: 60px; /* Adjust size as needed */
    height: 60px; /* Adjust size as needed */
    border-radius: 50%; /* Circle shape for guide images */
    margin-right: 15px;
}

.guide-info {
    display: flex;
    flex-direction: column; /* Stack local-guide and guide-name vertically */
}

.local-guide {
    color: #5BC2EE;
    font-family: "Poppins", Sans-serif;
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    font-style: normal;
    text-decoration: none;
    line-height: 13px;
    letter-spacing: 1px;
}

.guide-name {
    color: #2B2C31;
    font-family: "Poppins", Sans-serif;
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;
    font-style: normal;
    text-decoration: none;
    line-height: 13px;
}
.accordion-container {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
  
    padding: 80px;
}
@media (max-width: 576px) {
    .accordion-container{
        padding: 20px;
    }
}

.accordion {
    /* Set medium width */
    max-width: 600px; /* Optional: set max width */
    margin: auto; /* Centering */
}
.accordian-header{
    font-size: 21px;
    font-weight: bold !important;
    color :black !important
}
