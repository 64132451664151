.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: rgba(255, 255, 255, 0.8); /* Optional: Add a background overlay */
  }
  
  .spinner {
    border: 6px solid #f3f3f3;
    border-top: 6px solid #000000;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  