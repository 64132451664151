.backgroundSmallcard-image {
    position: relative;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    padding-bottom: 100px;
}

.backgroundSmallcard-image::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6); /* Black overlay with 60% opacity */
    z-index: 1;
}
.SmallCard  {
    position: relative;
    z-index: 3; /* To ensure content stays above the black overlay */
}
.SmallCardContent{
        position: relative;
        z-index: 3; 
}
.SmallCard h1{
    color: #FFFFFF;
    font-family: "Poppins", Sans-serif;
    font-size: 30px;
    font-weight: 600;
    text-transform: none;
    font-style: normal;
    text-decoration: none;
    line-height: 64px;
}
.SmallCardContent{
    color: #FFFFFF;
    font-family: "Poppins", Sans-serif;
}
.elementor-widget-container {
    margin: 0px 0px 0px 0px;
    /* padding: 40px 0px 30px 22px; */
    padding: 10px 0px 30px 0px;
}
.elementor-button {
    font-family: "Poppins", Sans-serif;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    font-style: normal;
    text-decoration: none;
    line-height: 18px;
    text-shadow: 0px 0px 0px #C85C0E;
    fill: #C85C0E;
    color: #C85C0E;
    background-color: #FFFFFF;
    border-radius: 200px 200px 200px 200px;
    padding: 17px 75px 17px 75px;
}