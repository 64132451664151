  .payment-status .container {
    text-align: center;
    max-width: 500px;
    padding: 50px;
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    position: relative;
    z-index: 10;
  }
  
  .payment-status .icon {
    font-size: 50px;
    margin-bottom: 20px;
  }
  
  .payment-status .icon.correct svg {
    color: #4caf50;
  }
  
  .payment-status .icon.wrong svg {
    color: #e74c3c;
  }
  
  .payment-status .status-message {
    font-size: 24px;
    font-weight: bold;
    color: #000000;
    font-family: "Times New Roman", Times, serif;
  }
  
  .payment-status .details {
    font-size: 16px;
    color: #36454f;
    margin-top: 10px;
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .payment-status .button {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background-color: #ff5722;
    text-decoration: none;
    border-radius: 5px;
  }
  
  .payment-status .confetti {
    position: absolute;
    width: 8px;
    height: 15px;
    opacity: 0.8;
    animation: fall linear infinite;
  }
  
  .payment-status .confetti.shape-square {
    width: 8px;
    height: 8px;
  }
  
  .payment-status .confetti.shape-rectangle {
    width: 10px;
    height: 15px;
  }
  
  .payment-status .confetti.shape-strip {
    width: 4px;
    height: 20px;
  }
  
  @keyframes fall {
    to {
      transform: translateY(100vh) rotate(45deg);
    }
  }
  