.About-p{
color: #7A7E94;
        font-size: 16px;
        font-weight: 600;
        text-transform: none;
        font-style: normal;
        text-align: justify;
        text-decoration: none;
        line-height: 25px;
}
.terms-h2{
color: #2B2C31;
    font-family: "Poppins", Sans-serif;
    font-size: 30px;
    font-weight: 400;
    text-transform: none;
    font-style: normal;
    text-decoration: none;
    line-height: 40px;
}