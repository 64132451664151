.card-btn {
    font-family: "Poppins", Sans-serif;
    font-size: 17px;
    font-weight: 600;
    border: 1px solid #d5d8dc;
    color: black;
    width: auto;
    /* Change width to auto */
    padding: 10px 20px;
    /* Add padding for better spacing */
    height: auto;
    /* Allow dynamic height based on content */
    border-radius: 50px;
    text-transform: uppercase;
    overflow: hidden;
    transition: background-color 0.3s ease;
    white-space: nowrap;
    /* Prevent button text from wrapping */
}

.card-btn:hover {
    background-color: white !important;
    color: black;
    border: 1px solid #d5d8dc;
}

.active-btn {
    border-color: #C85C0E !important;
    color: #C85C0E !important;
    box-shadow: 1px 1px;
}

.school-btn .col {
    display: flex;
    justify-content: center;
    /* Center buttons horizontally */
}
.card-button {
  padding: 10px;
  border: none;
  border-radius: 30px;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); /* Adjusted box-shadow */
  transition: background-color 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

/* Optional: Add hover effect */
.card-button:hover {
  background-color: #f0f0f0; /* Change background on hover */
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3); /* Slightly stronger shadow on hover */
}

.card-button img{
    width: 100%;
    object-fit: cover;
    border-radius: 30px;
}
/* Container to make cards stretch to the same height */
.row {
    display: flex;
    flex-wrap: wrap;
  }
  
  /* Flexbox for the cards to ensure same height dynamically */
  .card-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%; /* Ensure card takes the full height */
    padding: 10px;
    border: none;
    border-radius: 30px;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
  
  .card-container:hover {
    transform: scale(1.05); /* Enlarges the card by 5% */
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* Adds a subtle shadow */
  }
  
  
  /* Same image height for consistency */
  .card-container img {
    width: 100%;
    height: 320px; /* Set a uniform height for the image */
    object-fit: cover;
    border-radius: 30px;
  }
  
  /* Make sure card title and text are aligned */
  .card-title {
    text-align: left;
    color: #d35400;
    font-size: 21px;
    font-weight: bold;
    font-family: "Poppins", Sans-serif;
  }
  
  .card-text {
    font-family: "Poppins", Sans-serif;
    font-weight: bold;
    color: black;
    text-align: left;
    font-size: 18px;
    line-height: 1.4;
    margin-top: auto; /* Push text to the bottom to maintain alignment */
  }
  
  /* Ensure all cards within the row have equal height */
  .card-container {
    flex: 1; /* Allow cards to grow equally */
    min-height: 100%; /* Match the tallest card's height */
  }
  
  .card-detail-container {
    position: relative;
    color: white;
    text-align: center;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.5); /* Optional for overlay */
  }
  
  .card-detail-content {
    background: rgba(0, 0, 0, 0.6); /* Dark overlay for better text readability */
    padding: 20px;
    border-radius: 10px;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .card-detail-content h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .card-detail-content p {
    font-size: 1.2rem;
  }
  .elementor-background-overlay {
    background-color: #000000;
    opacity: 0.45;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}  

.read-button{
  background: #E86B26;
  color: white;
  border: none;
  border-radius: 30px;
  font-size: 14px;
  margin-top: 40px;
  height: 50px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1em;
  min-width: 100px; /* Set a minimum width for the button */
  box-shadow: 0px 4px 10px rgb(0 0 0 / 15%);
  padding: 0 20px;
}
