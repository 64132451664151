.backgroundcard-image {
    position: relative;
    background-size: cover;
    background-position: center;
    display: flex;
    height:'30vh';
    flex-direction: column;
    margin-bottom: 50px;
    padding-bottom: 100px;
}
@media (max-width: 576px) {
    .backgroundcard-image {
        position: relative;
        height: 110vh;
        background-size: cover;
        background-position: center;
        display: flex;
        flex-direction: column;
        margin-bottom: 50px;
        padding-bottom: 100px;
    }
}

.backgroundcard-image::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6); /* Black overlay with 60% opacity */
    z-index: 1;
}


.elementor-background-overlay  {
    z-index: 2; /* To keep your text and content above the overlay */
}

.CardDetailContent, .CardDetailSubtitle, .BackToHome {
    position: relative;
    z-index: 3; /* To ensure content stays above the black overlay */
}
  .CardDetailContent{
    background: rgba(0, 0, 0, 0.6); /* Dark overlay for better text readability */
    padding: 20px;
    border-radius: 10px;
    max-width: 600px;
    margin: 0 auto;
  }
  .CardDetailSubtitle h1{
    color: #FFFFFF;
    font-family: "Poppins", Sans-serif;
    font-size: 52px;
    font-weight: 600;
    text-transform: none;
    font-style: normal;
    text-decoration: none;
    line-height: 64px;
  }
  .CardDetailSubtitle p{
    text-align: left;
    color: #FFFFFF;
    font-family: "Poppins", Sans-serif;
    font-size: 20px;
    font-weight: 500;
    text-transform: none;
    font-style: normal;
    text-decoration: none;
    line-height: 25px;
  }
  .count-num{
    display: flex;
    flex-wrap: wrap;
    align-items: end;
    white-space: break-spaces;
  }
  .count-num span{
    font-family: "Poppins", Sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #FFFFFF;
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  }
  .count-num h1{
    font-family: "Poppins", Sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 60px;
    color: #FFFFFF;
    text-shadow: 0px 4px 10px rgb(0 0 0 / 15%);
    margin-bottom: -10px;
    margin-right: 10px;
  }
  @media (max-width: 576px) {
    .CardDetailSubtitle h1{
        font-size: 35px;
    }
    .CardDetailContent{
        margin-top: 100px;

        background: rgba(0, 0, 0, 0.4);
    }
  }
  .BackToHome {
    display: flex; /* Enable flexbox */
    justify-content: center; /* Horizontally center the content */
    align-items: center; /* Vertically center the content */
    background: rgba(0, 0, 0, 0.6); /* Dark overlay */
    border-radius: 10px;
    height: 100%; /* Ensure the container takes up the full height */
    padding: 30px;
  }
  
  .back-btn {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
    line-height: 18px;
    text-shadow: 0px 0px 0px #C85C0E;
    color: #C85C0E;
    border-radius: 200px;
    padding: 10px 20px; /* Add more horizontal padding for better appearance */
    border: 2px solid #C85C0E; /* Add a border to match the text color */
    background-color: #FFFFFF;
    cursor: pointer;
  }

  .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 40px 0px 30px 22px;
}
.BackToHome {
  display: flex; /* Enable flexbox */
  justify-content: center; /* Horizontally center the content */
  align-items: center; /* Vertically center the content */
  background: rgba(0, 0, 0, 0.6); /* Dark overlay */
  border-radius: 10px;
  height: 100%; /* Ensure the container takes up the full height */
}

.back-btn {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  line-height: 18px;
  text-shadow: 0px 0px 0px #C85C0E;
  color: #C85C0E;
  border-radius: 200px;
  padding: 10px 20px; /* Add more horizontal padding for better appearance */
  border: 2px solid #C85C0E; /* Add a border to match the text color */
  background-color: #FFFFFF;
  cursor: pointer;
}


.elementor-button {
    font-family: "Poppins", Sans-serif;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    font-style: normal;
    text-decoration: none;
    line-height: 18px;
    text-shadow: 0px 0px 0px #C85C0E;
    fill: #C85C0E;
    color: #C85C0E;
    background-color: #FFFFFF;
    border-radius: 200px 200px 200px 200px;
    padding: 17px 75px 17px 75px;
}