.backgroundread-image{
    background-image: url('./images/australian-school.png');
     position: relative;
    background-size: cover;
    background-position: center;
    display: flex;
    height:'100vh';
    flex-direction: column;
    margin-bottom: 50px;
    padding-bottom: 100px;
}
.backgroundread-image::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4); /* Black overlay with 60% opacity */
    z-index: 1;
}


.elementor-background-overlay {
    z-index: 2; /* To keep your text and content above the overlay */
}
.CardDetailContent, .CardDetailSubtitle {
    position: relative;
    z-index: 3; /* To ensure content stays above the black overlay */
}