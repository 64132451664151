.footer-bg {
    background: linear-gradient(135deg, #2d404b, #3b1c3d);
    color: white;
    padding: 40px 20px;
    font-family: 'Roboto', sans-serif;
}

.footer-content {
    display: flex;
    gap: 20px;
    margin-bottom: 40px;
}
.footer-logo {
    text-align: right; /* Align content to the right */
}
@media (max-width: 576px) {
    .footer-logo{
        text-align: center;
        margin-bottom: 30px;
    }
}
.footer-logo img {
    width: 200px;
height: 50px;
}

.footer-links {
    display: flex;
    justify-content: left;
    text-align: justify;
}

.footer-links ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column; /* Change layout to column */
    gap: 15px; /* Optional: Adjust space between links */
}

.footer-links ul li {
    display: inline;
}

.footer-links ul li a {
    color: white;
    font-size: 14px;
    text-transform: uppercase;
    text-decoration: none;
}

.footer-links ul li a:hover {
    text-decoration: underline;
}

.footer-social {
    text-align: right;
}

.social-icons {
    display: flex;
    gap: 20px;
    justify-content: flex-end;
}

.social-icons a {
    display: flex;
    align-items: center;
    justify-content: center;
    color: black; /* Icon color */
    padding: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%; /* Circular shape */
    font-size: 20px;
    background-color: white; /* Background color */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: shadow for depth */
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition */
}




.social-icons a:hover {
    color: #ff6b6b;
}

.footer-bottom {
   
    padding-top: 20px;
    display: flex;
    gap: 20px;
    align-items: center;
}
.terms{
    border-top: 1px solid rgba(255, 255, 255, 0.2);
}


.terms a {
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;
    text-decoration: none;
}

.terms a:hover {
    color: white;
    text-decoration: underline;
}
.links-and-icons {
    display: flex;
    justify-content: space-between; /* Space links and icons apart */
    align-items: center; /* Center vertically */
    width: 100%;
}

.quick-links {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column; /* Stack links vertically */
    gap: 15px; /* Space between links */
}

.social-icons {
    display: flex;
    gap: 20px; /* Space between icons */
}
.terms-content {
    display: flex;
    justify-content: space-between; /* Space the p and a tags apart */
    align-items: center; /* Vertically align both elements */
    width: 100%;
}

.terms p {
    margin: 0; /* Remove any default margin */
    color: white; /* Ensure the text color is white */
    font-size: 14px;
}

.terms a {
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;
    text-decoration: none;
}

.terms a:hover {
    color: white;
    text-decoration: underline;
}
